
import * as aroraProviderRuntime$smUc3V4_Xohpw7i4cikmLp9uQqf3WbChmX6kp5u0khQ from 'D:/deploy/UploadHidden/20250404-P903.327/arora-front/satellite/build-rest-88544c2a-d1e9-4710-8cf8-6ec86b1024a3/providers/aroraProvider.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "aroraProvider",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['aroraProvider']: { provider: aroraProviderRuntime$smUc3V4_Xohpw7i4cikmLp9uQqf3WbChmX6kp5u0khQ, defaults: {} }
}
        